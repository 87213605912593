<app-modal
  *ngIf="showModal"
  [size]="ModalSize.LARGE"
  [status]="termsModalStatus"
  [closeable]="false"
  class="terms"
>
  <div modal-body>
    <h1 class="terms__title">Terms & Conditions</h1>
    <div class="terms__content">
      <p>
        These Terms and Conditions, together with Our Privacy Policy and
        Acceptable Use Policy, govern the Customer (“You”, “Your”) when using
        the cloudgateway.co.uk website (the “Site”), and the
        my.cloudgateway.co.uk client portal (the “Portal”). They also govern
        Your relationship with the owner of the Site and Portal, namely Cloud
        Gateway Limited (“Us”, “Our”, “We”), a company registered under the laws
        of England and Wales (Company No. 10660712), with its registered office
        at The 7th & 8th Floors, 24 King William Street, London, EC4R 9AT,
        United Kingdom.
      </p>
      <p>
        Please read these Terms and Conditions carefully as they affect Your
        rights and liabilities under the law. By using Our Site and/or Portal
        You indicate that You accept these Terms and Conditions and that You
        agree to abide by them. If You do not agree with these Terms and
        Conditions, You do not have permission to access the contents of the
        Site or Portal and You should cease using them immediately.
      </p>
      <p>
        Through the Site and Portal, You may have access to various programs,
        software, web-based tools and other materials made available by Cloud
        Gateway now and in the future, including but not limited to: bulletins,
        white papers, and other technical publications; information on product
        certifications, product availability, product desupport, bug databases;
        service requests that You have submitted; software patches; bulletin
        board; and hyperlinks to websites not controlled by Cloud Gateway.
      </p>
      <h2 class="terms__heading">
        Clause 1. Access and use of the Site and Portal
      </h2>
      <p>
        You are responsible for making all arrangements necessary for You to
        have access to Our Site and Portal. You are also responsible for
        ensuring that all persons who access Our Site and Portal through Your
        internet connection are aware of these terms, and that they comply with
        them.
      </p>
      <h3 class="terms__heading terms__heading--small">Site</h3>
      <p>
        The Site may be accessed through any internet-connected mobile or
        personal computing device, and any browser listed below:
      </p>
      <table>
        <caption>
          Table showing browser support
        </caption>
        <thead>
          <th>Browser</th>
          <th>Supported versions</th>
        </thead>
        <tbody>
          <tr>
            <td>Chrome</td>
            <td>Latest</td>
          </tr>
          <tr>
            <td>Firefox</td>
            <td>Latest and extended support release (ESR)</td>
          </tr>
          <tr>
            <td>Edge</td>
            <td>2 most recent major versions</td>
          </tr>
          <tr>
            <td>Safari</td>
            <td>2 most recent major versions</td>
          </tr>
          <tr>
            <td>iOS</td>
            <td>2 most recent major versions</td>
          </tr>
          <tr>
            <td>Android</td>
            <td>2 most recent major versions</td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>
        Access to Our Site is permitted on a temporary basis, and We reserve the
        right to withdraw or amend the service We provide on Our Site without
        notice (see below). We will not be liable if for any reason Our Site is
        unavailable at any time or for any period.
      </p>
      <p>
        From time to time, We may restrict access to some parts of Our Site, or
        Our entire Site.
      </p>
      <p>
        The Site is provided to You free of charge for Your personal use subject
        to these Terms and Conditions and the Acceptable Use Policy. By using
        the Site, You agree to be bound by these Terms and Conditions.
      </p>
      <h3 class="terms__heading terms__heading--small">Portal</h3>
      <p>
        The Portal requires an internet-connected device running the supported
        devices listed above. The Portal also requires each user to have a
        mobile device capable of receiving SMS messages in order to provide
        multi-factor authentication.
      </p>
      <p>
        Access to Our Portal is permitted for the length of the contract into
        which You enter with Cloud Gateway Limited. We reserve the right to
        withdraw or amend access to the service We provide on Our Portal without
        notice (see below). We will not be liable if, for any reason, Our Site
        is unavailable at any time or for any period.
      </p>
      <p>
        The Portal may be provided to You as part of Our managed service and as
        described in any Proposal. It is subject to these Terms and Conditions
        and the Acceptable Use Policy. By using the Portal, You agree to be
        bound by these Terms and Conditions.
      </p>
      <strong>Portal log in procedure</strong>
      <p>
        The Portal is accessed via secure log in. Portal user details will be
        collected during Cloud Gateway service onboarding. When logging in for
        the first time, You will be prompted to read and accept these Terms and
        Conditions, and requested to change Your temporary password in
        accordance with the password strength policy We define. Portal user
        training can be provided to customers either in the form of a demo,
        guidance documentation or in-person training. We, at Our sole
        discretion, will decide which form(s) of training are most appropriate
        for each customer, and reserve the right to charge for this training.
      </p>
      <strong>Security and use of log in information</strong>
      <p>
        You must treat Your user ID, password, and any other piece of
        information as part of Our security procedures, as Confidential, and You
        must not disclose it. We have the right to disable any user
        identification code or password, whether chosen by You or allocated by
        us, at any time, if in Our opinion You have failed to comply with any of
        the provisions of these Terms and Conditions.
      </p>
      <p>
        You agree to immediately notify Cloud Gateway of any unauthorised use of
        Your account or any other breach of security. Cloud Gateway will not be
        liable for any loss You incur as a result of someone else using Your
        password and account with or without Your permission.
      </p>
      <p>You may not:</p>
      <ul>
        <li>Share log in details</li>
        <li>Leave Your user accounts logged in and unattended</li>
        <li>Use anyone else's log in details</li>
        <li>
          Allow third parties, contractors or suppliers to remotely access/take
          over Your PC or laptop via the Internet, as this could compromise the
          security of Your service
        </li>
      </ul>
      <strong>Portal roles and responsibilities</strong>
      <p>
        Cloud Gateway will provide You and Your Portal users with certain roles
        and permissions unique to Your specific requirements. Responsibility for
        user management and changes will depend on the roles You define during
        onboarding. You are responsible for managing the permissions for Portal
        users, and should review user access on a regular basis.
      </p>
      <p>
        Statements pertaining to Cloud Gateway's handling of Your personal and
        company data can be found in Our Privacy Policy.
      </p>
      <p>
        When using Our Site and/or Portal, You must comply with the provisions
        of Our Acceptable Use Policy (see below).
      </p>
      <h2 class="terms__heading">
        Clause 2. Amendments to the Site and Portal
      </h2>
      <p>
        We reserve the right at any time to change these Terms and Conditions by
        posting changes online. You are responsible for regularly reviewing
        information posted online to obtain prompt notice of any such changes.
        Your continued usage of the Site and Portal after changes are posted
        constitutes Your acceptance of the revised Terms and Conditions.
      </p>
      <p>
        We also reserve the right at any time and from time to time to modify or
        discontinue, temporarily or permanently, the Site or Portal with or
        without notice. You agree that We shall not be liable to You or to any
        third party for any modification, suspension or discontinuance of the
        Site or Portal.
      </p>
      <p>
        Site and/or Portal maintenance will be required from time to time, to
        launch new functionality or fix bugs. Should this happen, access will be
        temporarily suspended to all users. We will endeavour to carry out
        maintenance in such a way that is least disruptive, for example, at
        times of day where user traffic is lowest, or at weekends. This may not
        always be possible in the event of urgent or unexpected maintenance.
        Whenever possible, customers will be informed of upcoming scheduled Site
        or Portal maintenance via Our Service Desk.
      </p>
      <h2 class="terms__heading">Clause 3. Reliance on Information Posted</h2>
      <h3 class="terms__heading terms__heading--small">Site</h3>
      <p>
        Commentary and other materials posted on Our Site are not intended to
        amount to advice on which reliance should be placed. We therefore
        disclaim all liability and responsibility arising from any reliance
        placed on such materials by any visitor to Our Site, or by anyone who
        may be informed of any of its contents.
      </p>
      <h3 class="terms__heading terms__heading--small">Portal</h3>
      <p>
        Information within the Portal reflects Your use of Our services. This
        information is available to report on and extract. We endeavour to
        provide this information accurately.
      </p>
      <h2 class="terms__heading">Clause 4. Intellectual property</h2>
      <p>
        Cloud Gateway Limited owns or is licensed to use all intellectual
        property in the content of the Site and Portal, and reserve the right to
        pursue any intellectual property infringement in the Courts of the
        country where We suffer damage. No licence is granted to You in these
        Terms and Conditions to use any trade name or mark of point or their
        affiliated companies. You shall not assert any claim or ownership to any
        trade names or marks or to the goodwill or reputation of Cloud Gateway
        Limited or their affiliated companies. If You are unsure of the legality
        of Your use of the material on this website You should contact
        <a href="mailto:info@cloudgateway.co.uk" target="_blank" rel="noopener"
          >info&#64;cloudgateway.co.uk</a
        >.
      </p>
      <p>
        You should not export, copy or forward information obtained via the
        Portal in any way that is unrelated to the key functions of the Portal,
        without the written instructions of Cloud Gateway as the data
        controller.
      </p>
      <ul>
        <li>
          Site: You may print off one copy, and may download extracts, of any
          page(s) from Our Site for Your personal reference.
        </li>
        <li>
          Portal: You may screenshot and download extracts of any Portal page(s)
          for Your personal reference. Please note that screenshot extracts from
          the Portal may contain data pertaining to Your users, network and
          security activity and should be treated as Confidential.
        </li>
      </ul>
      <p>
        You must not modify paper or digital copies of any materials You have
        printed off or downloaded in any way, and You must not use any
        illustrations, photographs, video or audio sequences or any graphics
        separately from any accompanying text.
      </p>
      <p>
        Our status (and that of any identified contributors) as the authors of
        material on Our Site and Portal must always be acknowledged.
      </p>
      <p>
        You must not use any part of the materials on Our Site or Portal for
        commercial purposes without obtaining a licence to do so from Us or Our
        licensors.
      </p>
      <p>
        If You print off, copy or download any part of Our Site or Portal in
        breach of these terms of use, Your right to use Our Site will cease
        immediately and You must, at Our option, return or destroy any copies of
        the materials You have made.
      </p>
      <h2 class="terms__heading">Clause 5. Your liability</h2>
      <p>
        You must ensure that any personal information that You supply to Us is
        correct and complete. You must inform the Service Desk immediately of
        any changes to the information that You have supplied to us, such as
        user details. This includes removal of Portal users as part of Your
        leaver process, in the event (for example) of a member of staff leaving
        Your business.
      </p>
      <p>
        You, and not Us, are entirely responsible for all material that You make
        available on the Site and Portal, including (but not limited to) any
        uploaded documents, interactions via chatbot or service ticketing, and
        any other type of contribution via interactive elements of the Site and
        Portal.
      </p>
      <p>
        You agree that You must evaluate, and bear all risks associated with,
        the use of material available on the Site and Portal, including but not
        limited to any reliance upon the accuracy, completeness or usefulness of
        such material.
      </p>
      <p>You agree not to use the Site and Portal to:</p>
      <ol>
        <li>
          Post, transmit or otherwise make available any material that is false,
          inaccurate, unlawful, harmful, threatening, abusive, harassing,
          defamatory, vulgar, obscene, libellous, hateful or racially or
          otherwise objectionable; or
        </li>
        <li>Impersonate any person or entity; or</li>
        <li>
          Forge headers or otherwise manipulate identifiers in order to disguise
          the origin of any material posted on the Site; or
        </li>
        <li>
          Post, transmit or otherwise make available any material that You do
          not have the right to make available under any law or contractual or
          fiduciary relationships; or
        </li>
        <li>
          Post, transmit or otherwise make available any material that infringes
          any patent, trademark, trade secret, copyright or other proprietary
          rights of any party; or
        </li>
        <li>
          Post, transmit or otherwise make available any unsolicited or
          unauthorised advertising, promotional materials, “spam”, “junk mail”,
          “chain letters” or any other forms of solicitation; or
        </li>
        <li>
          Post, transmit or otherwise make available any material that contains
          software viruses or any other computer code, files, or programs
          designed to interrupt, destroy of limit the functionality of any
          computer software or hardware or telecommunications equipment; or
        </li>
        <li>
          Intentionally or unintentionally violate any national or international
          statute, law, decree, rule and/or regulation; or
        </li>
        <li>“Stalk” or harass another person; or</li>
        <li>Collect or store personal data about others.</li>
      </ol>
      <h2 class="terms__heading">Clause 6. Our liability</h2>
      <p>
        The Site and Portal are provided by Us, without any warranties or
        guarantees. We do not accept any liability for the quality of material
        on the Site or Portal, including but not limited to its accuracy,
        authenticity or correctness.
      </p>
      <p>
        Furthermore, We do not accept liability for technical problems that You
        may experience with the Site or Portal. All implied warranties are
        excluded to the maximum extent permitted by law.
      </p>
      <p>
        To the fullest extent permitted by law, You agree that We will not be
        liable to You or any third party for any consequential or incidental
        damages (including but not limited to loss of revenue, loss of
        anticipated savings, wasted expenditure, loss of privacy and loss of
        data) or any other indirect, special or punitive damages whatsoever that
        arise out of or are related to the Site or Portal.
      </p>
      <p>
        Nothing in these Terms and Conditions shall exclude liability for death
        or personal injury caused by negligence.
      </p>
      <h2 class="terms__heading">
        Clause 7. Handling of material on the Site and Portal
      </h2>
      <p>
        We reserve the right to remove any material on the Site and Portal at
        any time, without notifying You.
      </p>
      <p>
        You acknowledge and agree that We may preserve and disclose material
        posted by You on the Site and Portal if required (a) by law or legal
        process, (b) to enforce these Terms and Conditions, (c) to respond to
        claims that such material violates the rights of third parties, (d) to
        protect Our rights and/or property, or rights and/or the property of
        other users of the Site and Portal, and the general public.
      </p>
      <h2 class="terms__heading">
        Clause 8. Viruses, hacking and other offences
      </h2>
      <p>
        You must not misuse Our Site or Portal by knowingly introducing viruses,
        trojans, worms, logic bombs or other material which is malicious or
        technologically harmful. You must not attempt to gain unauthorised
        access to Our Site or Portal, the server on which Our Site and Portal
        are stored, or any server, computer or database connected to Our Site
        and Portal. You must not attack Our Site and Portal via a
        denial-of-service attack or a distributed denial-of service attack.
      </p>
      <p>
        By breaching this provision, You would commit a criminal offence under
        the Computer Misuse Act 1990. We will report any such breach to the
        relevant law enforcement authorities and We will cooperate with those
        authorities by disclosing Your identity to them. In the event of such a
        breach, Your right to use Our Site and Portal will cease immediately.
      </p>
      <p>
        We will not be liable for any loss or damage caused by a distributed
        denial-of-service attack, viruses or other technologically harmful
        material that may infect Your computer equipment, computer programs,
        data or other proprietary material due to Your use of Our Site or
        Portal, or to Your downloading of any material posted on it, or on any
        website linked to it.
      </p>
      <h2 class="terms__heading">Clause 9. Termination</h2>
      <p>
        You agree that We, in Our sole discretion, may terminate Your use of
        this Site and/or Portal, and remove and discard any material You have
        posted on the Site or Portal, for any reason, including, without
        limitation, if We believe that You have violated or acted inconsistently
        with the letter or spirit of these Terms and Conditions. You agree that
        any termination of Your access to the Site or Portal under any provision
        of these Terms and Conditions may be effected without prior notice, and
        acknowledge and agree that We may immediately bar any further access to
        the Site or Portal. Furthermore, You agree that We shall not be liable
        to You or any third party for any termination of Your access to the Site
        or Portal.
      </p>
      <h2 class="terms__heading">
        Clause 10. Material submitted or made available for inclusion on the
        Site and Portal
      </h2>
      <h3 class="terms__heading terms__heading--small">Site</h3>
      <p>
        We do not claim ownership of material You submit or make available for
        inclusion on the Site. However, with respect to material You submit or
        make available for inclusion on the Site, You grant Us a worldwide,
        royalty free, non-exclusive, perpetual, irrevocable and fully
        sub-licensable licence to use, distribute, reproduce, modify, adapt,
        publish, translate, publicly perform and publicly display such material
        (in whole or in part) and to incorporate such material into other works
        in any format or medium now known or later developed, subject to Our
        privacy policy.
      </p>
      <h3 class="terms__heading terms__heading--small">Portal</h3>
      <p>
        We do not claim ownership of material You submit or make available for
        inclusion on the Portal. Information pertaining to Your users, network
        and security activity will be treated as Confidential.
      </p>
      <p>
        Unless given Your permission, We agree not to use, distribute,
        reproduce, modify, adapt, publish, translate, publicly perform and
        publicly display such material (in whole or in part) and to incorporate
        such material into other works in any format or medium now known or
        later developed, subject to Our privacy policy.
      </p>

      <h2 class="terms__heading">Clause 11. Indemnity</h2>
      <p>
        You agree to indemnify and hold Cloud Gateway Limited, and its
        subsidiaries, affiliates, officers, agents, and employees (collectively,
        the “Indemnified Parties”) harmless from any loss, damage, injury,
        claim, demand, cost, including reasonable attorneys' fees, or penalty
        awarded against or incurred or paid by any of the Indemnified Parties
        due to or arising out of material You submit, post, transmit or
        otherwise make available on the Site or Portal, Your use of the Site,
        Your violation of these Terms and Conditions, or Your violation of any
        rights of another.
      </p>
      <h2 class="terms__heading">Clause 12. Links</h2>
      <p>
        As a convenience to you, the Site and Portal may include links to other
        websites or material that are beyond Our control. Their inclusion must
        not be taken to imply any endorsement or validation by Us of the content
        of the linked website. Cloud Gateway Limited does not accept
        responsibility or liability for any losses or penalties that may be
        incurred or for the accuracy of hyperlinks to third party websites or
        the content of such third party websites.
      </p>
      <p>
        Neither Our Site nor Portal may be framed on any other site, nor may You
        create a link to any part of Our Site other than the home page. We
        reserve the right to withdraw linking permission without notice. The
        website from which You are linking must comply in all respects with the
        content standards set out in these Terms and Conditions.
      </p>
      <p>
        If You wish to make any use of material on Our Site other than that set
        out above, please address Your request to
        <a href="mailto:info@cloudgateway.co.uk" target="_blank" rel="noopener"
          >info&#64;cloudgateway.co.uk</a
        >.
      </p>
      <h2 class="terms__heading">Clause 13. International use</h2>
      <p>
        Cloud Gateway Limited makes no promise that materials on the Site or
        Portal are appropriate or available for use in locations outside the
        United Kingdom, and accessing the Site or Portal from territories where
        its contents are illegal or unlawful is prohibited. If You choose to
        access this Site or Portal from locations outside of the United Kingdom,
        You do so at Your own risk and You are responsible for compliance with
        the international laws and regulations from the location from which You
        access the Site or Portal.
      </p>
      <p>
        The courts of England and Wales will have exclusive jurisdiction over
        any claim arising from, or related to, a visit to Our Site and Portal
        although We retain the right to bring proceedings against You for breach
        of these conditions in Your country of residence or any other relevant
        country. These Terms and Conditions are governed by the laws of England
        and Wales.
      </p>
      <h2 class="terms__heading">Clause 14. Privacy Policy</h2>
      <p>
        By using the Site and/or Portal, You also agree to Our Privacy Policy,
        which can be viewed
        <a
          href="https://www.cloudgateway.co.uk/privacy-policy/"
          target="_blank"
          rel="noopener"
          >here</a
        >. This Privacy Policy applies both to Site visitors and Portal users.
      </p>
      <p>
        Please read the Privacy Policy carefully as it contains important
        information on how We use Your personal information.
      </p>
      <h2 class="terms__heading">Clause 15. Analytics</h2>
      <p>
        In order to track bugs, review user experience data and improve the
        portal and its features over time, we use analytics and data insights
        tools such as Hotjar to capture anonymised data about your visit in the
        portal. We have obfuscated all sensitive personal data except for your
        account name, which allows us to better tailor the experience of the
        portal around you and your company. By accepting our Terms & Conditions,
        you are consenting to the use of these analytics tools for internal
        Cloud Gateway use only.
      </p>
      <h2 class="terms__heading">Clause 16. Miscellaneous</h2>
      <p>
        If any clause of these Terms and Conditions should be determined to be
        illegal, invalid or otherwise unenforceable, then to the extent that
        Term or Condition is illegal, invalid or unenforceable, it shall be
        severed and deleted. The remaining Terms and Conditions shall survive,
        remain in full force and effect, and continue to be binding and
        enforceable. No waiver by Cloud Gateway Limited of breach of obligation
        arising under these Terms and Conditions shall constitute a waiver of
        any other breach.
      </p>
      <p>
        You may not assign, sub-license or otherwise dispose of Your rights
        under these Terms and Conditions.
      </p>
      <p>
        A person who is not a party to these Terms and Conditions shall have no
        rights under the Contract (Rights of Third Parties) Act 1999 to enforce
        any terms of these Terms and Conditions. These Terms and Conditions
        shall be governed by and construed in accordance with the laws of
        England and Wales, and any disputes shall be decided only by the courts
        of England and Wales.
      </p>
      <h1 class="terms__heading terms__heading--large">
        Cloud Gateway Acceptable Use Policy Website and Portal
      </h1>

      <h2 class="terms__heading">Acceptable Use Policy</h2>
      <p>
        This Acceptable Use Policy sets out the terms between You and Us under
        which You may access Our Site cloudgateway.co.uk (Our Site), and Our
        Portal my.cloudgateway.co.uk (Our Portal). This acceptable use policy
        applies to all users of, and visitors to, Our Site and Portal.
      </p>

      <p>
        Your use of Our Site and Portal means that You accept, and agree to
        abide by, all the policies in this acceptable use policy, which
        supplement Our Terms and Conditions.
      </p>

      <p>
        <strong>cloudgateway.co.uk</strong> is a Site operated by Cloud Gateway
        Limited registered in England and Wales under company number 10660712,
        with its registered office at The 7th & 8th Floors, 24 King William
        Street, London, EC4R 9AT, UK.
      </p>

      <p>
        <strong>my.cloudgateway.co.uk</strong> is a Portal operated by Cloud
        Gateway Limited registered in England and Wales under company number
        10660712, with its registered office at The 7th & 8th Floors, 24 King
        William Street, London, EC4R 9AT, UK.
      </p>

      <p>
        The Site and Portal have been developed to be fully compliant with the
        ABPI Code of Conduct.
      </p>

      <h2 class="terms__heading">Prohibited uses</h2>

      <p>
        You may use Our Site and Portal only for lawful purposes. You may not
        use them:
      </p>

      <ul>
        <li>
          In any way that breaches any applicable local, national or
          international law or regulation; or
        </li>
        <li>
          In any way that is unlawful or fraudulent, or has any unlawful or
          fraudulent purpose or effect; or
        </li>
        <li>
          For the purpose of harming or attempting to harm minors in any way; or
        </li>
        <li>
          To send, knowingly receive, upload, download, use or re-use any
          material which does not comply with Our content standards; or
        </li>
        <li>
          To transmit, or procure the sending of, any unsolicited or
          unauthorised advertising or promotional material or any other form of
          similar solicitation (spam); or
        </li>
        <li>
          To knowingly transmit any data, send or upload any material that
          contains viruses, Trojan horses, worms, time-bombs, keystroke loggers,
          spyware, adware or any other harmful programs or similar computer code
          designed to adversely affect the operation of any computer software or
          hardware.
        </li>
      </ul>

      <p>You also agree:</p>

      <ul>
        <li>
          Not to reproduce, duplicate, copy or re-sell any part of Our Site or
          Portal in contravention of the provisions of Our Terms and Conditions;
          and
        </li>
        <li>
          Not to access without authority, interfere with, damage or disrupt:
        </li>
        <ul>
          <li>any part of Our Site or Portal;</li>
          <li>
            any equipment or network on which Our Site or Portal is stored;
          </li>
          <li>any software used in the provision of Our Site or Portal; or</li>
          <li>
            any equipment or network or software owned or used by any third
            party.
          </li>
        </ul>
      </ul>

      <h2 class="terms__heading">Interactive Services</h2>

      <p>
        We may, from time to time, provide interactive services on Our Site or
        Portal. Where We do provide any interactive service, We will provide
        clear information to You about the kind of service offered, if it is
        moderated and what form of moderation is used (including whether it is
        human or technical).
      </p>

      <p>
        We will do Our best to assess any possible risks for users from third
        parties when they use any interactive service provided on Our Site or
        Portal, and We will decide in each case whether it is appropriate to use
        moderation of the relevant service (including what kind of moderation to
        use) in the light of those risks. However, We are under no obligation to
        oversee, monitor or moderate any interactive service We provide on Our
        Site or Portal, and We expressly exclude Our liability for any loss or
        damage arising from the use of any interactive service by a user in
        contravention of Our content standards, whether the service is moderated
        or not.
      </p>

      <p>
        Where We do moderate an interactive service, We will normally provide
        You with a means of contacting the moderator, should a concern or
        difficulty arise.
      </p>

      <h2 class="terms__heading">Content standards</h2>

      <p>
        These content standards apply to any and all material which You
        contribute to Our Site and Portal (contributions), and to any
        interactive services associated with it.
      </p>

      <p>
        You must comply with the spirit of the following standards as well as
        the letter. The standards apply to each part of any contribution as well
        as to its whole.
      </p>

      <p>Contributions must:</p>

      <ul>
        <li>Be accurate (where they state facts); and</li>
        <li>Be genuinely held (where they state opinions); and</li>
        <li>
          Comply with applicable law in the UK and in any country from which
          they are posted.
        </li>
      </ul>

      <p>Contributions must not:</p>

      <ul>
        <li>Contain any material which is defamatory of any person; or</li>
        <li>
          Contain any material which is obscene, offensive, hateful or
          inflammatory; 0r
        </li>
        <li>Promote sexually explicit material; or</li>
        <li>Promote violence; or</li>
        <li>
          Promote discrimination based on race, sex, religion, nationality,
          disability, sexual orientation or age; or
        </li>
        <li>
          Infringe any copyright, database right or trademark of any other
          person; or
        </li>
        <li>Be likely to deceive any person; or</li>
        <li>
          Be made in breach of any legal duty owed to a third party, such as a
          contractual duty or a duty of confidence; or
        </li>
        <li>Promote any illegal activity; or</li>
        <li>
          Be threatening, abuse or invade another's privacy, or cause annoyance,
          inconvenience or needless anxiety; or
        </li>
        <li>
          Be likely to harass, upset, embarrass, alarm or annoy any other
          person; or
        </li>
        <li>
          Be used to impersonate any person, or to misrepresent Your identity or
          affiliation with any person; or
        </li>
        <li>
          Give the impression that they emanate from us, if this is not the
          case; or
        </li>
        <li>
          Advocate, promote or assist any unlawful act such as (by way of
          example only) copyright infringement or computer misuse.
        </li>
      </ul>

      <h2 class="terms__heading">
        Security Management, Risk and Breach Reporting
      </h2>

      <p>
        Both You and Cloud Gateway have a joint responsibility to protect
        personal and/or sensitive information from unauthorised access,
        modification, disclosure or misuse.
      </p>

      <p>
        If You believe that Site or Portal security has been breached, data has
        been compromised, or there is an imminent risk of this happening, You
        may alert Cloud Gateway directly via the following methods.
      </p>

      <p>
        <span class="underline"
          ><a
            href="mailto:info@cloudgateway.co.uk"
            target="_blank"
            rel="noopener"
            >info&#64;cloudgateway.co.uk</a
          ></span
        >
      </p>
      <p>+44 (0) 203 870 2444</p>

      <h2 class="terms__heading">Suspension and termination</h2>

      <p>
        We will determine, in Our discretion, whether there has been a breach of
        this Acceptable Use Policy through Your use of Our Site and Portal. When
        a breach of this policy has occurred, We may take such action as We deem
        appropriate.
      </p>

      <p>
        Failure to comply with this Acceptable Use Policy constitutes a material
        breach of the Terms and Conditions upon which You are permitted to use
        Our Site, and may result in Our taking all or any of the following
        actions:
      </p>

      <ul>
        <li>
          Immediate, temporary or permanent withdrawal of Your right to use Our
          Site and/or Portal; and/or
        </li>
        <li>
          Immediate, temporary or permanent removal of any posting or material
          uploaded by You to Our Site and/or Portal; and/or
        </li>
        <li>Issue a warning to you; and/or</li>
        <li>
          Legal proceedings against You for reimbursement of all costs on an
          indemnity basis (including, but not limited to, reasonable
          administrative and legal costs) resulting from the breach; and/or
        </li>
        <li>Further legal action against you; and/or</li>
        <li>
          Disclosure of such information to law enforcement authorities as We
          reasonably feel is necessary.
        </li>
      </ul>

      <p>
        We exclude liability for actions taken in response to breaches of this
        Acceptable Use Policy. The responses described in this policy are not
        limited, and We may take any other action We reasonably deem
        appropriate.
      </p>

      <h2 class="terms__heading">Changes to the Acceptable Use Policy</h2>

      <p>
        We may revise this Acceptable Use Policy at any time by amending this
        page. You are expected to check this page from time to time to take
        notice of any changes We make, as they are legally binding on you. Some
        of the provisions contained in this Acceptable Use Policy may also be
        superseded by provisions or notices published elsewhere on Our Site or
        Portal.
      </p>
    </div>
  </div>

  <div modal-footer class="terms__footer">
    <div class="terms__buttons">
      <p>
        By continuing to use the My Cloud Gateway Portal, you are agreeing to
        and accepting the Terms & Conditions of service outlined above.
      </p>
      <app-button
        [type]="ButtonType.BUTTON"
        [role]="ButtonRole.SECONDARY"
        [style]="ButtonStyle.EMPHASISED"
        (buttonClick)="acceptTerms()"
        >Accept &amp; Continue</app-button
      >
    </div>
    <div class="terms__download"></div>
  </div>
</app-modal>
